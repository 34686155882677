import { RouteObject } from "react-router-dom";
import StoreFront from "./layouts/StoreFront";
import HomePage from "./pages/HomePage";
import Checkout from "./pages/Checkout";
import CheckoutSuccess from "./pages/CheckoutSuccess";
import CategoryPage from "./pages/CategoryPage";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import CategoriesPage from "./pages/CategoriesPage";
import TermsOfService from "./pages/TermsOfService";
import FAQ from "./pages/FAQ";
import ShippingReturns from "./pages/ShippingReturns";
import Support from "./pages/Support";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <StoreFront />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "/category/:slug",
        element: <CategoryPage />,
      },
      {
        path: "/categories",
        element: <CategoriesPage />,
      },
      {
        path: "/checkout",
        element: <Checkout />,
      },
      {
        path: "/checkout-success",
        element: <CheckoutSuccess />,
      },
      {
        path: "/about",
        element: <AboutUs />,
      },
      {
        path: "/contact",
        element: <ContactUs />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "/terms-of-service",
        element: <TermsOfService />,
      },
      {
        path: "/faq",
        element: <FAQ />,
      },
      {
        path: "/shipping-returns",
        element: <ShippingReturns />,
      },
      {
        path: "/support",
        element: <Support />,
      },
    ],
    // errorElement: <ErrorPage />,
  },
];
