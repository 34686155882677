interface LoadingSpinnerProps {
  size?: 'small' | 'large';
}

export const LoadingSpinner = ({ size = 'small' }: LoadingSpinnerProps) => {
  const sizeClasses = size === 'large' ? 'w-8 h-8' : 'w-5 h-5';

  return (
    <div className={`animate-spin rounded-full border-2 border-gray-300 border-t-blue-600 ${sizeClasses}`} />
  );
}; 