import React from 'react';

const TermsAndConditions: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-8">Terms and Conditions</h1>
      
      <div className="prose prose-indigo max-w-none">
        <p className="text-gray-600 mb-6">Last updated: {new Date().toLocaleDateString()}</p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Agreement to Terms</h2>
          <p className="text-gray-600 mb-4">
            By accessing and using Grocery Hub's website and services, you agree to be bound by these Terms and Conditions. If you disagree with any part of these terms, you may not access our services.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Use of Services</h2>
          <p className="text-gray-600 mb-4">
            Our services are intended for personal, non-commercial use. You agree to:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Provide accurate and complete information</li>
            <li>Maintain the security of your account</li>
            <li>Use the services in compliance with all applicable laws</li>
            <li>Not engage in any fraudulent or deceptive activities</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. Product Information</h2>
          <p className="text-gray-600 mb-4">
            We strive to provide accurate product descriptions and pricing. However, we reserve the right to:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Correct any errors in product descriptions or pricing</li>
            <li>Modify or discontinue products without notice</li>
            <li>Limit the quantity of items purchased</li>
            <li>Refuse service to anyone</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Orders and Payment</h2>
          <p className="text-gray-600 mb-4">
            By placing an order, you:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Agree to pay all charges for your order</li>
            <li>Confirm that all information provided is accurate</li>
            <li>Authorize us to process your payment</li>
            <li>Understand that prices are subject to change</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Delivery and Returns</h2>
          <p className="text-gray-600 mb-4">
            We aim to deliver your orders within the estimated time frame. For returns:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Items must be returned within 14 days of delivery</li>
            <li>Products must be unused and in original packaging</li>
            <li>Refunds will be processed within 5-7 business days</li>
            <li>Shipping costs for returns are the customer's responsibility</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Intellectual Property</h2>
          <p className="text-gray-600 mb-4">
            All content on our website, including text, graphics, logos, and software, is our property and is protected by intellectual property laws.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Limitation of Liability</h2>
          <p className="text-gray-600 mb-4">
            Grocery Hub shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our services.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">8. Changes to Terms</h2>
          <p className="text-gray-600 mb-4">
            We reserve the right to modify these terms at any time. Changes will be effective immediately upon posting to our website. Your continued use of our services constitutes acceptance of the modified terms.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">9. Contact Information</h2>
          <p className="text-gray-600 mb-4">
            For questions about these Terms and Conditions, please contact us at:
          </p>
          <p className="text-gray-600">
            Email: legal@groceryhub.com<br />
            Phone: +1 (555) 123-4567<br />
            Address: 123 Grocery Street, City, State 12345
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions; 