import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCategories } from "../hooks/useCategories";
import { useInView } from "react-hook-inview";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { superstate } from "@superstate/core";

const LIMIT = 12;
const offset = superstate(0);

const CategoriesPage: React.FC = () => {
  const [ref, isVisible] = useInView({
    threshold: 0.5,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const { categories, fetchMore, loading } = useCategories({
    pagination: {
      limit: LIMIT,
      offset: 0,
    },
  });

  const handleOnEndReached = useCallback(async () => {
    if (hasMore && !isLoading && !loading) {
      setIsLoading(true);
      try {
        const result = await fetchMore({
          variables: {
            pagination: {
              offset: offset.now() + LIMIT,
              limit: LIMIT,
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            offset.set(offset.now() + LIMIT);
            if (!fetchMoreResult) return prev;
            
            // Check if we have more data to load
            if (fetchMoreResult.getCategories && fetchMoreResult.getCategories.length < LIMIT) {
              setHasMore(false);
            }
            
            return {
              getCategories:
                prev.getCategories?.concat(fetchMoreResult.getCategories || []) ||
                [],
            };
          },
        });
      } catch (error) {
        console.error("Error fetching more categories:", error);
        setHasMore(false);
      } finally {
        setIsLoading(false);
      }
    }
  }, [fetchMore, hasMore, isLoading, loading]);

  useEffect(() => {
    if (isVisible) {
      handleOnEndReached();
    }
  }, [isVisible, handleOnEndReached]);

  if (loading && !categories?.length) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner size="large" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">All Categories</h1>
        
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          {categories?.map((category) => (
            <Link
              key={category.id}
              to={`/category/${category.id}`}
              className="group relative aspect-square overflow-hidden rounded-lg bg-gray-100 hover:opacity-95 transition"
            >
              <img
                src={category.image}
                alt={category.name}
                className="h-full w-full object-cover object-center group-hover:scale-105 transform transition-transform"
              />
              <div className="absolute inset-0 bg-black bg-opacity-25 flex items-end">
                <div className="w-full p-4 text-center bg-white bg-opacity-90">
                  <p className="text-sm font-medium text-gray-900">
                    {category.name}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>

        {/* Infinite Scroll Trigger / Footer */}
        <div ref={ref} className="mt-8 text-center">
          {isLoading ? (
            <div className="flex justify-center py-4">
              <LoadingSpinner size="small" />
            </div>
          ) : hasMore ? (
            <p className="text-sm text-gray-500">
              Scroll for more categories
            </p>
          ) : (
            <p className="text-sm text-gray-500">You've reached the end</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoriesPage; 