import React, { useState } from 'react';

const FAQ: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const faqs = [
    {
      question: "How do I place an order?",
      answer: "To place an order, simply browse our products, add items to your cart, and proceed to checkout. You'll need to provide your delivery address and payment information. Once confirmed, we'll process your order and deliver it to your specified location."
    },
    {
      question: "What are your delivery hours?",
      answer: "We deliver from 8:00 AM to 10:00 PM, seven days a week. Delivery times may vary based on your location and order volume. You can select your preferred delivery time slot during checkout."
    },
    {
      question: "How much is the delivery fee?",
      answer: "Delivery fees vary based on your location and order total. Orders above $50 qualify for free delivery. For orders below $50, a standard delivery fee of $5.99 applies. Special delivery zones may have different rates."
    },
    {
      question: "Can I modify or cancel my order?",
      answer: "You can modify or cancel your order within 30 minutes of placing it. After this time, the order will be processed and cannot be changed. For urgent modifications, please contact our customer service immediately."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept all major credit cards (Visa, MasterCard, American Express), debit cards, and digital payment methods like Apple Pay and Google Pay. We also accept cash on delivery for certain areas."
    },
    {
      question: "How do I track my order?",
      answer: "Once your order is confirmed, you'll receive a tracking link via email and SMS. You can also track your order status through your account dashboard on our website or mobile app."
    },
    {
      question: "What is your return policy?",
      answer: "We accept returns within 14 days of delivery for unopened and unused products. Perishable items cannot be returned. To initiate a return, please contact our customer service with your order number and reason for return."
    },
    {
      question: "Do you offer bulk discounts?",
      answer: "Yes, we offer special discounts for bulk orders. The discount percentage varies based on the quantity and type of products. Please contact our sales team for bulk order inquiries and custom pricing."
    },
    {
      question: "How do I contact customer service?",
      answer: "You can reach our customer service team through multiple channels: phone (+1-555-123-4567), email (support@groceryhub.com), or live chat on our website. We're available 24/7 to assist you."
    },
    {
      question: "Do you offer subscription services?",
      answer: "Yes, we offer subscription services for regular grocery deliveries. You can set up weekly or monthly deliveries of your favorite products. Subscribers receive exclusive discounts and priority delivery slots."
    }
  ];

  const toggleAccordion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-8">Frequently Asked Questions</h1>
      
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="border border-gray-200 rounded-lg overflow-hidden"
          >
            <button
              className="w-full px-6 py-4 text-left bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500"
              onClick={() => toggleAccordion(index)}
            >
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-medium text-gray-900">
                  {faq.question}
                </h2>
                <svg
                  className={`w-5 h-5 text-gray-500 transform transition-transform ${
                    openIndex === index ? 'rotate-180' : ''
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </button>
            {openIndex === index && (
              <div className="px-6 py-4 bg-gray-50">
                <p className="text-gray-600">{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="mt-12 bg-gray-50 p-6 rounded-lg">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Still have questions?</h2>
        <p className="text-gray-600 mb-4">
          If you can't find the answer you're looking for, our customer service team is here to help.
        </p>
        <div className="space-y-2">
          <p className="text-gray-600">
            <span className="font-medium">Email:</span> support@groceryhub.com
          </p>
          <p className="text-gray-600">
            <span className="font-medium">Phone:</span> +1 (555) 123-4567
          </p>
          <p className="text-gray-600">
            <span className="font-medium">Hours:</span> 24/7 Support
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQ; 