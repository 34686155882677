import React from 'react';

const Support: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-8">Support Center</h1>

      <div className="prose prose-indigo max-w-none">
        {/* Quick Help Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">Quick Help</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="text-xl font-medium text-gray-900 mb-4">Order Support</h3>
              <ul className="space-y-2 text-gray-600">
                <li>• Track your order</li>
                <li>• Modify or cancel orders</li>
                <li>• Delivery issues</li>
                <li>• Payment problems</li>
              </ul>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="text-xl font-medium text-gray-900 mb-4">Account Support</h3>
              <ul className="space-y-2 text-gray-600">
                <li>• Password reset</li>
                <li>• Account settings</li>
                <li>• Profile updates</li>
                <li>• Security concerns</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Contact Options */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">Contact Us</h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <div className="bg-gray-50 p-6 rounded-lg text-center">
              <h3 className="text-xl font-medium text-gray-900 mb-4">Phone Support</h3>
              <p className="text-gray-600 mb-2">+233 (024) 015-4242</p>
              <p className="text-sm text-gray-500">24/7 Support Available</p>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg text-center">
              <h3 className="text-xl font-medium text-gray-900 mb-4">Email Support</h3>
              <p className="text-gray-600 mb-2">groceryhub929@gmail.com</p>
              <p className="text-sm text-gray-500">Response within 24 hours</p>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg text-center">
              <h3 className="text-xl font-medium text-gray-900 mb-4">Live Chat</h3>
              <p className="text-gray-600 mb-2">Available 9 AM - 9 PM GMT</p>
              <p className="text-sm text-gray-500">Click the chat icon to start</p>
            </div>
          </div>
        </section>

        {/* FAQ Preview */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">Common Questions</h2>

          <div className="bg-gray-50 p-6 rounded-lg">
            <div className="space-y-4">
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">How do I track my order?</h3>
                <p className="text-gray-600">
                  You can track your order through your account dashboard or using the tracking link sent to your email.
                </p>
              </div>

              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">What are your delivery hours?</h3>
                <p className="text-gray-600">
                  We deliver from 8:00 AM to 10:00 PM, seven days a week. Delivery times may vary based on your location.
                </p>
              </div>

              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">How do I return an item?</h3>
                <p className="text-gray-600">
                  Please contact our customer service to initiate a return. Returns must be made within 14 days of delivery.
                </p>
              </div>
            </div>

            <div className="mt-6 text-center">
              <a
                href="/faq"
                className="text-primary-600 hover:text-primary-500 font-medium"
              >
                View all FAQs →
              </a>
            </div>
          </div>
        </section>

        {/* Business Hours */}
        <section className="bg-gray-50 p-6 rounded-lg">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Support Hours</h2>
          <div className="space-y-2 text-gray-600">
            <p><span className="font-medium">Phone Support:</span> 24/7</p>
            <p><span className="font-medium">Email Support:</span> 24/7 (response within 24 hours)</p>
            <p><span className="font-medium">Live Chat:</span> Monday - Friday, 9:00 AM - 9:00 PM GMT</p>
            <p><span className="font-medium">In-Person Support:</span> Monday - Friday, 9:00 AM - 5:00 PM GMT</p>
          </div>

          <div className="mt-6">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Visit Our Office</h3>
            <p className="text-gray-600">
              Antelope Street<br />
              Accra,<br />
              Ghana
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Support; 