import React from 'react';

const ShippingReturns: React.FC = () => {
    return (
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <h1 className="text-4xl font-bold text-gray-900 mb-8">Shipping & Returns</h1>
            <div className="prose prose-indigo max-w-none">
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold text-gray-900 mb-4">Shipping Policy</h2>
                    <p className="text-gray-600 mb-4">
                        At Grocery Hub, we are committed to delivering your orders promptly and efficiently. We offer complimentary standard shipping on all orders over GHS 300. For orders below this amount, a flat rate of GHS 5 will be applied for standard shipping. Expedited shipping options are available for an additional fee, ensuring your items reach you even faster.
                    </p>
                    <p className="text-gray-600 mb-4">
                        Our team processes orders within 1-2 business days. Standard shipping typically takes 3-5 business days, while expedited shipping options can deliver your order within 1-3 business days. Please note that shipping times may vary based on your location and external factors.
                    </p>
                </section>
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold text-gray-900 mb-4">Return Policy</h2>
                    <p className="text-gray-600 mb-4">
                        We strive to ensure your satisfaction with every purchase. If you are not completely satisfied, we accept returns within 30 days of purchase. Items must be returned in their original condition, unworn, and with all tags attached. To initiate a return, please contact our customer service team with your order number and reason for return.
                    </p>
                    <p className="text-gray-600 mb-4">
                        Upon receiving and inspecting your return, we will notify you of the approval or rejection of your refund. If approved, your refund will be processed, and a credit will be applied to your original method of payment within a few business days. Please note that the time it takes for the refund to reflect in your account may vary depending on your payment provider.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default ShippingReturns;