import { useCallback, useEffect, useState } from "react";
import { ProductItem } from "../../components";
import { useProducts } from "../../hooks/useProducts";
import { superstate } from "@superstate/core";
import { useInView } from "react-hook-inview";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { Link } from "react-router-dom";
import { useCategories } from "../../hooks/useCategories";
import { useBestSellers } from "../../hooks/useBestSellers";

type Props = {};

const LIMIT = 20;
const offset = superstate(0);

const HomePage = (props: Props) => {
  const { products, fetchMore, length } = useProducts({
    pagination: {
      limit: LIMIT,
      offset: 0,
    },
  });

  const [ref, isVisible] = useInView({
    threshold: 1,
  });
  const [isLoading, setIsLoading] = useState(false);

  const { categories } = useCategories({
    pagination: {
      limit: 6,
      offset: 0,
    },
  });
  // const { collections } = useCollections();
  // const { products: bestSellers } = useBestSellers({ limit: 4 });

  const handleOnEndReached = useCallback(async () => {
    if (length > offset.now() + LIMIT && !isLoading) {
      setIsLoading(true);
      try {
        await fetchMore({
          variables: {
            pagination: {
              offset: offset.now() + LIMIT,
              limit: LIMIT,
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            offset.set(offset.now() + LIMIT);
            if (!fetchMoreResult) return prev;
            return {
              getProducts:
                prev.getProducts?.concat(fetchMoreResult.getProducts || []) ||
                [],
              getProductsLength: fetchMoreResult.getProductsLength || 0,
            };
          },
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      handleOnEndReached();
    }
  }, [isVisible, handleOnEndReached]);

  if (!products) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner size="large" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Banner Section */}
      <div
        className="relative h-64 sm:h-80 md:h-96 bg-cover bg-center flex items-center justify-center"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/hero-section.png)`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50" />
        <div className="relative z-10 text-center px-4">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-white mb-3 drop-shadow">
            Welcome to Our Store
          </h1>
          <p className="text-md sm:text-lg md:text-xl text-white mb-5 drop-shadow">
            Explore thousands of products across categories
          </p>
          <Link
            to="/categories"
            className="inline-block bg-primary-600 text-white px-4 py-2 rounded-md text-sm sm:text-base font-medium hover:bg-primary-500 transition-colors"
          >
            Shop Best Sellers
          </Link>
        </div>
      </div>

      {/* Main Content */}
      <main>
        {/* Categories Section */}
        <section
          aria-labelledby="categories-heading"
          className="bg-white py-8 px-4 sm:px-6 lg:px-8"
        >
          <div className="mx-auto max-w-7xl">
            <div className="md:flex md:items-center md:justify-between mb-6">
              <h2
                id="categories-heading"
                className="text-2xl font-bold tracking-tight text-gray-900"
              >
                Shop by Category
              </h2>
              <Link
                to="/categories"
                className="text-sm font-medium text-primary-600 hover:text-primary-500"
              >
                View all<span aria-hidden="true"> →</span>
              </Link>
            </div>
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-6">
              {categories?.map((category) => (
                <Link
                  key={category.id}
                  to={`/category/${category.id}`}
                  className="group relative aspect-square overflow-hidden rounded-lg bg-gray-100 hover:opacity-95 transition"
                >
                  <img
                    src={category.image}
                    alt={category.name}
                    className="h-full w-full object-cover object-center group-hover:scale-105 transform transition-transform"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-25 flex items-end">
                    <div className="w-full p-4 text-center bg-white bg-opacity-90">
                      <p className="text-sm font-medium text-gray-900">
                        {category.name}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </section>

        {/* Best Sellers Section */}
        {/* <section
          aria-labelledby="bestsellers-heading"
          className="bg-white py-8 px-4 sm:px-6 lg:px-8"
        >
          <div className="mx-auto max-w-7xl">
            <div className="md:flex md:items-center md:justify-between mb-6">
              <h2
                id="bestsellers-heading"
                className="text-2xl font-bold tracking-tight text-gray-900"
              >
                Best Sellers
              </h2>
              <Link
                to="/best-sellers"
                className="text-sm font-medium text-primary-600 hover:text-primary-500"
              >
                View all<span aria-hidden="true"> →</span>
              </Link>
            </div>
            <div className="grid grid-cols-2 gap-y-8 gap-x-4 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
              {bestSellers?.map((product) => (
                <ProductItem product={product} key={product.id} />
              ))}
            </div>
          </div>
        </section> */}

        {/* Trending Products Section */}
        <section
          aria-labelledby="trending-heading"
          className="bg-gray-50 py-8 px-4 sm:px-6 lg:px-8"
        >
          <div className="mx-auto max-w-7xl">
            <div className="md:flex md:items-center md:justify-between mb-6">
              <h2
                id="trending-heading"
                className="text-2xl font-bold tracking-tight text-gray-900"
              >
                Trending Products
              </h2>
            </div>
            <div className="grid grid-cols-2 gap-y-8 gap-x-4 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
              {products?.map((product) => (
                <ProductItem product={product} key={product.id} />
              ))}
            </div>

            {/* Infinite Scroll Trigger / Footer */}
            <div ref={ref} className="mt-8 text-center">
              {isLoading ? (
                <div className="flex justify-center py-4">
                  <LoadingSpinner size="small" />
                </div>
              ) : length > offset.now() + LIMIT ? (
                <p className="text-sm text-gray-500">
                  Scroll for more products
                </p>
              ) : (
                <p className="text-sm text-gray-500">You've reached the end</p>
              )}
            </div>
          </div>
        </section>
      </main>


    </div>
  );
};

export default HomePage;
