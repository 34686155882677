import React from 'react';

const AboutUs: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">About Us</h1>
        <p className="text-xl text-gray-600">Your trusted partner in grocery shopping</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-16">
        <div>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Our Story</h2>
          <p className="text-gray-600 mb-4">
            Founded with a vision to revolutionize the grocery shopping experience, Grocery Hub has been serving customers
            with quality products and exceptional service since our inception. We believe in making grocery shopping
            convenient, enjoyable, and accessible to everyone.
          </p>
          <p className="text-gray-600">
            Our commitment to quality, customer satisfaction, and community involvement has helped us grow into a trusted
            name in the grocery industry.
          </p>
        </div>
        <div>
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Our Mission</h2>
          <p className="text-gray-600 mb-4">
            At Grocery Hub, our mission is to provide our customers with the freshest products, competitive prices, and
            an unparalleled shopping experience. We strive to make grocery shopping simple and efficient while maintaining
            the highest standards of quality and service.
          </p>
          <p className="text-gray-600">
            We are dedicated to supporting local producers and contributing to the growth and well-being of our
            communities.
          </p>
        </div>
      </div>

      <div className="bg-gray-50 rounded-lg p-8">
        <h2 className="text-2xl font-semibold text-gray-900 mb-6 text-center">Our Values</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center">
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Quality</h3>
            <p className="text-gray-600">We maintain the highest standards in product quality and freshness.</p>
          </div>
          <div className="text-center">
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Service</h3>
            <p className="text-gray-600">Customer satisfaction is at the heart of everything we do.</p>
          </div>
          <div className="text-center">
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Community</h3>
            <p className="text-gray-600">We actively support and contribute to our local communities.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs; 