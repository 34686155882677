import React from 'react';

const TermsOfService: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-8">Terms of Service</h1>
      
      <div className="prose prose-indigo max-w-none">
        <p className="text-gray-600 mb-6">Last updated: {new Date().toLocaleDateString()}</p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Acceptance of Terms</h2>
          <p className="text-gray-600 mb-4">
            By accessing and using Grocery Hub's website and services, you agree to be bound by these Terms of Service. If you disagree with any part of these terms, you may not access our services.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Service Description</h2>
          <p className="text-gray-600 mb-4">
            Grocery Hub provides an online platform for grocery shopping and delivery services. We connect customers with local grocery stores and facilitate the ordering and delivery of products.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. User Accounts</h2>
          <p className="text-gray-600 mb-4">
            To use our services, you must:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Be at least 18 years old</li>
            <li>Provide accurate and complete information</li>
            <li>Maintain the security of your account</li>
            <li>Not share your account credentials</li>
            <li>Be responsible for all activities under your account</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Ordering and Payment</h2>
          <p className="text-gray-600 mb-4">
            When placing an order, you agree to:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Provide accurate delivery information</li>
            <li>Pay all charges for your order</li>
            <li>Authorize us to process your payment</li>
            <li>Accept that prices may change</li>
            <li>Understand that product availability may vary</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Delivery and Returns</h2>
          <p className="text-gray-600 mb-4">
            Our delivery terms include:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Delivery times are estimates only</li>
            <li>Someone must be present to receive the order</li>
            <li>Returns must be made within 14 days</li>
            <li>Products must be in original condition</li>
            <li>Refunds will be processed within 5-7 business days</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. User Conduct</h2>
          <p className="text-gray-600 mb-4">
            You agree not to:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Use our services for any illegal purpose</li>
            <li>Interfere with the proper functioning of our services</li>
            <li>Attempt to gain unauthorized access to our systems</li>
            <li>Harass or harm other users</li>
            <li>Post false or misleading information</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Service Modifications</h2>
          <p className="text-gray-600 mb-4">
            We reserve the right to:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-4">
            <li>Modify or discontinue any part of our services</li>
            <li>Change prices at any time</li>
            <li>Update these terms without notice</li>
            <li>Restrict access to our services</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">8. Contact Information</h2>
          <p className="text-gray-600 mb-4">
            For questions about these Terms of Service, please contact us at:
          </p>
          <p className="text-gray-600">
            Email: legal@groceryhub.com<br />
            Phone: +1 (555) 123-4567<br />
            Address: 123 Grocery Street, City, State 12345
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService; 