import { Link } from 'react-router-dom'

type Props = {}

const Footer = (props: Props) => {
    return (
        <footer className="bg-white py-8 px-4 sm:px-6 lg:px-8" >
            <div className="mx-auto max-w-7xl">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div>
                        <h3 className="text-lg font-bold text-gray-900 mb-4">Contact Us</h3>
                        <p className="text-sm text-gray-600">
                            Email: groceryhub929@gmail.com
                        </p>
                        <p className="text-sm text-gray-600">
                            Phone: +233 (024) 015-4242
                        </p>
                        <p className="text-sm text-gray-600">
                            Address: Antelope St, North Legon, Accra
                        </p>
                    </div>
                    <div>
                        <h3 className="text-lg font-bold text-gray-900 mb-4">Quick Links</h3>
                        <ul className="text-sm text-gray-600">
                            <li>
                                <Link to="/about" className="hover:text-primary-600">About Us</Link>
                            </li>
                            <li>
                                <Link to="/contact" className="hover:text-primary-600">Contact</Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy" className="hover:text-primary-600">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to="/terms-of-service" className="hover:text-primary-600">Terms of Service</Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-bold text-gray-900 mb-4">Customer Service</h3>
                        <ul className="text-sm text-gray-600">
                            <li>
                                <Link to="/faq" className="hover:text-primary-600">FAQ</Link>
                            </li>
                            <li>
                                <Link to="/shipping-returns" className="hover:text-primary-600">Shipping & Returns</Link>
                            </li>
                            <li>
                                <Link to="/support" className="hover:text-primary-600">Support</Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-bold text-gray-900 mb-4">Follow Us</h3>
                        <ul className="text-sm text-gray-600">
                            <li>
                                <a href="https://facebook.com" className="hover:text-primary-600">Facebook</a>
                            </li>
                            <li>
                                <a href="https://x.com/groceryhubstore" className="hover:text-primary-600">X</a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/groceryhub_store?igsh=MWF5N3g3eG1ycGhvMw==" className="hover:text-primary-600">Instagram</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer >
    )
}

export default Footer